import { PolicyV2Type, ProductPropsV2Type } from './ApiV2'
import { ReactNode } from 'react'

export type UserAuthInput = { firstName?: string; lastName?: string; ssn: string }

export type TestPropsType = Record<string, string | number>

export type PolicyReviewCardProps = {
  policy: Partial<PolicyV2Type>
  productProps: ProductPropsV2Type
  children?: ReactNode
}

export type PolicyVerifyCardContentProps = {
  policy: Partial<PolicyV2Type>
}

export type TranslatedTextType = string | string[]

export const isKeyboardEvent = (
  event: React.KeyboardEvent<HTMLDivElement> | React.ClipboardEvent<HTMLDivElement>
): event is React.KeyboardEvent<HTMLDivElement> => {
  return !!(event as React.KeyboardEvent<HTMLDivElement>).key
}

export const isClipboardEventEvent = (
  event: React.KeyboardEvent<HTMLDivElement> | React.ClipboardEvent<HTMLDivElement>
): event is React.ClipboardEvent<HTMLDivElement> => {
  return !!(event as React.ClipboardEvent<HTMLDivElement>).clipboardData
}
