const CONFIG = {
  EMAIL: 'EXTRA@digisure.se',
  //FIXME: Would be fixed in next feature commit
  ADVANCE_PURCHASE_INFO_LINK:
    'https://extra.digisure.se/wp-content/uploads/Forkopsinformation-010222.pdf',
  SUPPORT_LINK: '',
  INSURANCE: '',
  MAIN_LINK: {
    vehicle: 'http://extra.digisure.se/',
  },
  MYPAGES_LOGOUT_REDIRECT: {
    vehicle: 'http://extra.digisure.se/',
  },
  PRIVACY_POLICY_LINK: 'https://www.digisure.no/personopplysninger',
  PARTNER_PRIVACY_POLICY_LINK: '',
  PARTNER_NAME: 'circlek',
  PARTNER_NAME_AVTALEGIRO: 'Circlek',
  VIPPS_AUTH: false,
  TRANSLATION_LANGUAGES: ['sv-SE'],
  GOOGLE_RECAPTCHA_LINKS: {
    PRIVACY: 'https://policies.google.com/privacy?hl=sv',
    TERMS: 'https://policies.google.com/terms?hl=sv',
  },
  FNOL: {
    PageWindshield: {
      iconLink: 'https://booking.bilglass.no/',
    },
    PageRoadsideAssistance: {
      iconLink: 'https://rsa2.falck.no/',
    },
  },
  DOCUMENTS: {
    PRIVACY_STATEMENT: '',
    LP: {
      IPID: '',
      ANSVAR: '',
      DELKASKO: '',
      KASKO: '',
      SUPER: '',
    },
  },
}

export default CONFIG
