import React, { FC } from 'react'
import { CustomClassName } from '../../../types/interface/styles.interface'
import { getDataComponentName } from '@dg-util'
import BrandConfig from 'BrandConfig'
import { ReCaptchaPrivacyTerms } from '@dg-shared'

const AuthDecisionText: FC<CustomClassName> = ({}) => {
  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <ReCaptchaPrivacyTerms links={BrandConfig.GOOGLE_RECAPTCHA_LINKS} />
    </div>
  )
}

export default AuthDecisionText
