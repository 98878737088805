import { FormValidationType } from '../../../types/interface/form.interface'
import QuoteAndBuyFormValidation from './qb/validation'

interface BrandFormValidation {
  qb: FormValidationType
}

const FormValidation: BrandFormValidation = {
  qb: QuoteAndBuyFormValidation,
}

export default FormValidation
