import { StateRoute } from '../../../../../types/interface/fsm.interface'
import {
  QBReview,
  QBOwnership,
  QBCompareDecision,
  QBCompareBenefitsPage,
} from '../../../../../pages/qb'
import { QBCompareInit } from '../../../../../pages/qb/Insurely'
import { FSMCommonStatePaths } from '../../../../../FSM/shared/routes'
import QBComparePage from '../../../../../pages/qb/Insurely/Compare/QBComparePage'
import QBCompareListPage from '../../../../../pages/qb/Insurely/CompareList/QBCompareListPage'
import { FSMCommonTransitions } from '../../../../../FSM/shared/constants'

const {
  QB_OWNERSHIP,
  QB_OWNER,
  INSURELY,
  INSURELY_COMPARE_INIT,
  INSURELY_COMPARE,
  INSURELY_COMPARARISON_LIST,
  INSURELY_COMPARE_BENEFITS,
  INSURELY_COMPARE_DECISION,
  QB_REVIEW,
} = FSMCommonTransitions

const FSMVehicleStatePathsSE: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  {
    is: { [QB_OWNERSHIP]: INSURELY_COMPARE_DECISION },
    page: QBCompareDecision,
  },
  {
    is: { [QB_OWNERSHIP]: QB_REVIEW },
    page: QBReview,
  },
  {
    is: { [QB_OWNERSHIP]: QB_OWNER },
    page: QBOwnership,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARE_INIT },
    page: QBCompareInit,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARE },
    page: QBComparePage,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARARISON_LIST },
    page: QBCompareListPage,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARE_BENEFITS },
    page: QBCompareBenefitsPage,
  },
]

export { FSMVehicleStatePathsSE }
