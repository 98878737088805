import { LoadScriptProps } from '@react-google-maps/api/'

export const GOOGLE_MAP_API_KEY = 'AIzaSyDlTr4XSaCOuDuM0tKGBhKd9xwjKyH2XTY'

export const GOOGLE_MAP_URL_LIST = {
  geocodeLatLngUrl: (lat: number, lng: number) =>
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`,
  geolocateUrl: () =>
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAP_API_KEY}`,
}

export const GOOGLE_MAPS_LIBRARIES: LoadScriptProps['libraries'] = ['places']

export const GOOGLE_MAP_OPTIONS = {
  disableControlUI: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
}
