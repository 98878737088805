import { FSMConfigVehicleSE, FSMOptionsVehicleSE } from './qb/vehicle/config'
import { FSMVehicleStatePathsSE } from './qb/vehicle/routes'
import { FSMInputType } from '../../../types/FSM'
import { FlowName } from '../../../config'
import { ProductName } from '../../../types/interface/quote.interface'

const BrandFSMInput: Partial<FSMInputType> = {
  [FlowName.QUOTE_AND_BUY]: {
    [ProductName.VEHICLE]: {
      config: FSMConfigVehicleSE,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsVehicleSE,
      routes: FSMVehicleStatePathsSE,
    },
  },
}

export default BrandFSMInput
