import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { TextField, FormControl, InputAdornment } from '@material-ui/core'
import { isClipboardEventEvent, isKeyboardEvent } from '../../../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customInput: {
      marginBottom: theme.spacing(2.5),
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none',
        },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
      '& > p': {
        color: theme.palette.info.contrastText,
      },
    },
    customLabel: {
      color: theme.brand.colors.textSecondary,
      '& + .MuiInput-formControl': {
        marginTop: theme.spacing(1),
      },
      position: 'static',
      lineHeight: 1.4, // 22
    },
    formControl: {
      width: '100%',
    },
  })
)

const RFFCustomTextField: FC<FieldRenderProps<unknown>> = ({
  input,
  meta,
  inputAdornmentText,
  fieldLabel,
  customLabel,
  ...props
}) => {
  const classes = useStyles()

  const sanitizeNumberInput = (
    event: React.KeyboardEvent<HTMLDivElement> | React.ClipboardEvent<HTMLDivElement>
  ) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight']

    // Sanitize non numeric characters for number or tel input
    if (
      ['number', 'tel'].includes(input.type) &&
      ((isKeyboardEvent(event) &&
        isNaN(event.key as unknown as number) &&
        !allowedKeys.includes(event.key)) ||
        (isClipboardEventEvent(event) &&
          isNaN(event.clipboardData.getData('text') as unknown as number)))
    ) {
      event.preventDefault()
    }
  }

  const isError: boolean = (meta.error || meta.submitError) && !meta.pristine
  const { maxLength } = props
  const inputMode = input.type === 'number' ? 'numeric' : 'text'

  return (
    <FormControl className={classes.formControl} error={isError}>
      {!fieldLabel && customLabel}
      <TextField
        InputLabelProps={{
          shrink: true,
          className: classes.customLabel,
        }}
        inputProps={{ maxLength, ['data-testid']: `text-${input.name}`, inputMode }}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={props.autoFocus}
        fullWidth
        type={input.type || 'text'}
        className={`${props.customClass} ${classes.customInput}`}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        onPaste={(event) => sanitizeNumberInput(event)}
        onKeyDown={(event) => sanitizeNumberInput(event)}
        error={isError}
        id={`text-${input.name}`}
        placeholder={props.fieldPlaceholder ? props.fieldPlaceholder : ''}
        multiline={props.multiline || false}
        label={fieldLabel || null}
        defaultValue={input.value || props.fieldDefaultValue}
        helperText={meta.error}
        InputProps={
          inputAdornmentText && {
            startAdornment: <InputAdornment position='start'>{inputAdornmentText}</InputAdornment>,
          }
        }
      />
    </FormControl>
  )
}

export default RFFCustomTextField
