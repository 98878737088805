import { FORM_ID, FORMAT_YEAR_MONTH_DAY_HYPHEN, MILEAGE_TEST_ID } from '../../../../constants'
import AuthCustomLabel from '../../../../pages/qb/Auth/Content/AuthCustomLabel'
import { FormConfigType } from '../../../../types/interface/form.interface'
import {
  RFFCustomDatePicker,
  RFFCustomSelectV2,
  RFFCustomSwitch,
  RFFCustomTextField,
} from '@dg-shared'
import PhoneFieldLabel from '../../../../pages/qb/Verify/Content/PhoneFieldLabel'
import SuperCoverCustomLabel from '../../../../pages/qb/Change/Content/SuperCoverCustomLabel'
import { CoverageTypeKeyType } from '../../../../types/interface/quote.interface'

const FormConfig: FormConfigType = {
  [FORM_ID.AUTH]: {
    vehicle: {
      id: FORM_ID.AUTH,
      fields: {
        ssn: {
          name: 'ssn',
          type: 'tel',
          component: RFFCustomTextField,
          customLabel: AuthCustomLabel,
        },
        regNum: {
          name: 'regNum',
          component: RFFCustomTextField,
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.COMPARE_AUTH]: {
    vehicle: {
      id: FORM_ID.COMPARE_AUTH,
      fields: {
        ssn: {
          name: 'ssn',
          type: 'tel',
          component: RFFCustomTextField,
          labelPlacement: 'top',
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.CHANGE]: {
    vehicle: {
      id: FORM_ID.CHANGE,
      fields: {
        CarPackage: {
          name: 'CarPackage',
          component: RFFCustomSelectV2,
          componentProps: {
            fetchOptions: true,
          },
        },
        SuperCoverage: {
          name: 'SuperCoverage',
          type: 'checkbox',
          component: RFFCustomSwitch,
          labelPlacement: 'top',
          customLabel: SuperCoverCustomLabel,
          dependency: [
            {
              fieldName: 'CarPackage',
              value: [CoverageTypeKeyType.halv, CoverageTypeKeyType.hel],
            },
          ],
          initialValue: false,
        },
        CarComprehensiveDeductible: {
          name: 'CarComprehensiveDeductible',
          component: RFFCustomSelectV2,
          dependency: [
            {
              fieldName: 'CarPackage',
              value: CoverageTypeKeyType.hel,
            },
          ],
          initialValue: '4500',
          componentProps: {
            fetchOptions: true,
          },
        },
        AnnualMileage: {
          name: 'AnnualMileage',
          component: RFFCustomSelectV2,
          testId: MILEAGE_TEST_ID,
          componentProps: {
            fetchOptions: true,
          },
        },
        YoungDrivers: {
          name: 'YoungDrivers',
          type: 'checkbox',
          component: RFFCustomSwitch,
          labelPlacement: 'top',
        },
      },
      initialValues: {
        CarPackage: CoverageTypeKeyType.halv,
      },
    },
  },
  [FORM_ID.VERIFY]: {
    vehicle: {
      id: FORM_ID.VERIFY,
      fields: {
        policyDate: {
          name: 'policyDate',
          type: 'tel',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldInputVariant: 'outlined',
            fieldMinDate: Date.now(),
            // +1 year from now
            fieldMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime(),
            formatType: FORMAT_YEAR_MONTH_DAY_HYPHEN,
            iconColor: 'secondary',
          },
        },
        email: {
          name: 'email',
          type: 'email',
          component: RFFCustomTextField,
        },
        phone: {
          name: 'phone',
          type: 'tel',
          component: RFFCustomTextField,
          customLabel: PhoneFieldLabel,
        },
      },
      initialValues: {},
    },
  },
}

export default FormConfig
