import { get, has } from 'lodash'
import guards from '../../../../../FSM/shared/guards'
import { mergeDeep } from '../../../../../service/util.service'
import { Events, FSMCommonTransitions } from '../../../../../FSM/shared/constants'
import actions from '../../../../../FSM/shared/actions'
import sharedFSMconfig, { MachineConfigType } from '../../../../../FSM/shared/config'

const {
  AUTH,
  AUTH_REGULAR,
  QB_OWNERSHIP,
  INSURELY,
  INSURELY_COMPARARISON_LIST,
  INSURELY_COMPARE_BENEFITS,
  INSURELY_COMPARE,
  INSURELY_COMPARE_INIT,
  QB_REVIEW,
  QB_VERIFY,
  QB_CHANGE,
  QB_PURCHASED,
  QB_PURCHASED_DONE,
  ERROR,
  HISTORY,
} = FSMCommonTransitions

const FLOW_MACHINE_ID = 'QBFlowMachineVehicleSE'

const FSMConfig: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH,
  context: {
    authorized: false,
    authMethod: '',
  },
  states: {
    [AUTH]: {
      initial: AUTH_REGULAR,
      states: {
        [AUTH_REGULAR]: {
          on: {
            [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
            [Events.AUTH_ERROR]: AUTH_REGULAR,
          },
          exit: ['setUserAuthorized', 'setRegularAuth'],
        },
      },
    },
    [QB_OWNERSHIP]: {
      initial: QB_REVIEW,
      states: {
        [QB_REVIEW]: {
          on: {
            [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${AUTH}`, actions: 'signOut' },
            [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
            [Events.CHANGE]: `#${FLOW_MACHINE_ID}.${QB_CHANGE}`,
            [Events.WRONG_PRODUCT_ID]: `#${FLOW_MACHINE_ID}.${AUTH}`,
            [Events.START_COMPARE]: `#${FLOW_MACHINE_ID}.${INSURELY}`,
          },
        },
        [HISTORY]: {
          type: 'history',
        },
      },
    },
    [QB_CHANGE]: {
      on: {
        [Events.BACK]: QB_OWNERSHIP,
        [Events.NEXT]: QB_OWNERSHIP,
      },
    },
    [QB_VERIFY]: {
      on: {
        [Events.BACK]: `${QB_OWNERSHIP}.${HISTORY}`,
        [Events.NEXT]: QB_PURCHASED,
        [Events.PAYMENT_SUCCESS]: QB_PURCHASED,
      },
    },
    [QB_PURCHASED]: {
      initial: QB_PURCHASED_DONE,
      states: {
        [QB_PURCHASED_DONE]: {
          type: 'final',
          on: {
            [Events.BACK]: `#${FLOW_MACHINE_ID}.${AUTH}`,
          },
        },
        [HISTORY]: {
          type: 'history',
        },
      },
    },
    [INSURELY]: {
      initial: INSURELY_COMPARE_INIT,
      states: {
        [INSURELY_COMPARE_INIT]: {
          on: {
            [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
            [Events.COMPARE]: INSURELY_COMPARE,
            [Events.COMPARE_LIST]: INSURELY_COMPARARISON_LIST,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${QB_REVIEW}`,
          },
        },
        [INSURELY_COMPARARISON_LIST]: {
          on: {
            [Events.NEXT]: INSURELY_COMPARE,
            [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${HISTORY}`,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${QB_REVIEW}`,
          },
        },
        [INSURELY_COMPARE]: {
          on: {
            [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
            [Events.COMPARE]: INSURELY_COMPARE_BENEFITS,
            [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${HISTORY}`,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${QB_REVIEW}`,
          },
        },
        [INSURELY_COMPARE_BENEFITS]: {
          on: {
            [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
            [Events.BACK]: INSURELY_COMPARE,
            [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${QB_REVIEW}`,
          },
        },
      },
    },
    [ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: AUTH,
            cond: (ctx) =>
              has(ctx.backPath, QB_OWNERSHIP) && get(ctx.backPath, QB_OWNERSHIP) === QB_REVIEW,
          },
          {
            target: `${QB_OWNERSHIP}.${HISTORY}`,
            cond: (ctx) => has(ctx.backPath, QB_OWNERSHIP),
          },
          {
            target: `${QB_PURCHASED}.${HISTORY}`,
            cond: (ctx) => has(ctx.backPath, QB_PURCHASED),
          },
          {
            target: INSURELY,
            cond: (ctx) => has(ctx.backPath, INSURELY),
          },
        ],
      },
    },
  },
}

const FSMConfigVehicleSE = mergeDeep(FSMConfig, sharedFSMconfig)
const FSMOptionsVehicleSE = { guards, actions }

export { FSMConfigVehicleSE, FSMOptionsVehicleSE }
