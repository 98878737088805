import React, { FC } from 'react'
import VehicleCardContentSE from '../../../pages/qb/Verify/Content/VehicleSE'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { getDataComponentName } from '@dg-util'
import { QuoteV2Type } from '../../../types/ApiV2'

interface CardVehicleProps {
  offer: Partial<QuoteV2Type>
}

const useStyles = makeStyles(() =>
  createStyles({
    disclaimerText: {
      marginBottom: 0,
    },
  })
)

const VerifyCardContent: FC<CardVehicleProps> = ({ offer }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useStyles()

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <VehicleCardContentSE offer={offer} />
      <Typography color='textDisclaimer' className={classes.disclaimerText} size='small'>
        {t('priceTotalDisclaimer')}
      </Typography>
    </div>
  )
}

export default VerifyCardContent
