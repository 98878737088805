import Joi from '@hapi/joi'
import format from 'date-fns/format'
import { REGEX_SE_PRODUCT_ID, REGEX_SE_SSN } from '../../../../constants/regex-constants'
import { FORM_ID, FORMAT_DAY_MONTH_YEAR_DOT } from '../../../../constants/forms-constants'
import { FormValidationType } from '../../../../types/interface/form.interface'

const FormValidation: FormValidationType = {
  [FORM_ID.AUTH]: {
    vehicle: Joi.object({
      ssn: Joi.string().required().empty().regex(REGEX_SE_SSN).messages({
        'string.base': 'auth.form.ssn.validation.base',
        'string.empty': '',
        'string.pattern.base': 'auth.form.ssn.validation.base',
        'any.required': '',
      }),
      regNum: Joi.string().required().empty().regex(REGEX_SE_PRODUCT_ID).messages({
        'string.base': 'auth.form.regNum.validation.base',
        'string.empty': 'auth.form.regNum.validation.base',
        'string.pattern.base': 'auth.form.regNum.validation.base',
        'any.required': '',
      }),
    }),
  },
  [FORM_ID.COMPARE_AUTH]: {
    vehicle: Joi.object().keys({
      ssn: Joi.string().required().empty().regex(REGEX_SE_SSN).messages({
        'string.base': 'compareAuth.form.ssn.validation.base',
        'string.empty': '',
        'string.pattern.base': 'compareAuth.form.ssn.validation.base',
        'any.required': '',
      }),
    }),
  },
  [FORM_ID.CHANGE]: {
    vehicle: Joi.object().keys({
      CarPackage: Joi.string().required(),
      CarComprehensiveDeductible: Joi.string().optional().allow(null).messages({
        'string.base': `Base validator`,
        'string.valid': `Base validator`,
      }),
      SuperCoverage: Joi.boolean().optional().allow(null),
      AnnualMileage: Joi.string().required().empty(),
      YoungDrivers: Joi.boolean().required().empty().messages({
        'date.base': `Base validator`,
        'date.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
  },
  [FORM_ID.VERIFY]: {
    vehicle: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'verify.form.policyDate.validation.base',
            'date.empty': 'verify.form.policyDate.validation.base',
            'date.max': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'verify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'verify.form.email.validation.base',
            'string.email': 'verify.form.email.validation.base',
            'string.empty': 'verify.form.email.validation.base',
            'any.required': 'verify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(10)
          .pattern(/^07[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'verify.form.phone.validation.base',
            'string.length': 'verify.form.phone.validation.base',
            'string.pattern.base': 'verify.form.phone.validation.base',
            'any.required': 'verify.form.phone.validation.base',
          }),
        agreementConfirm: Joi.boolean().required().valid(true).messages({
          'any.only': '',
          'any.required': '',
        }),
        consentSharing: Joi.boolean().required().valid(true).messages({
          'any.only': '',
          'any.required': '',
        }),
      })
    },
  },
}

export default FormValidation
