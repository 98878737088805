import React from 'react'
import { Typography } from '@dg-shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: `3px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      margin: theme.spacing(2),
    },
  })
)

const TestComponent = () => {
  const { wrapper } = useStyles()
  return (
    <div className={wrapper}>
      <Typography type='h1' color='main'>
        Circle K Brand Test Component
      </Typography>
      <Typography>
        This Test component placed in a different folder and resolved by webpack modules resolver
      </Typography>
      <Typography color='main'>src/brand/circlek/components/testComponent.tsx</Typography>
    </div>
  )
}

export default TestComponent
