import QuoteAndBuyFormConfig from './qb/config'
import { FormConfigType } from '../../../types/interface/form.interface'

interface BrandFormConfig {
  qb: FormConfigType
}

const FormConfig: BrandFormConfig = {
  qb: QuoteAndBuyFormConfig,
}

export default FormConfig
