import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useOfferReviewPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
    },
    shortDescription: {
      marginTop: theme.spacing(2),
    },
    disclaimer: {
      marginBottom: theme.spacing(1),
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    additionalPadding: {
      marginBottom: theme.spacing(2),
    },
    contactCustomerSwitchWrapper: {
      margin: '0 20px 10px 20px',
      gap: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        margin: '0 5px 10px 10px',
      },
      ...theme.brand.assets.layout?.contactCustomerSwitchWrapper,
    },
    backgroundImage: {
      [theme.breakpoints.up('md')]: {
        ...theme.brand.assets.pageBackgrounds.review,
      },
    },
  })
)

export default useOfferReviewPageStyles
