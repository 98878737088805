import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T, FORM_ID } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import Loading from '../../../pages/qb/Loading/Page'
import Card from '../../../pages/qb/Ownership/Card'
import useLogic from '../../../pages/qb/Ownership/useLogic'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { withHidden, LMBasicV3FSM, LDContainerV2FSM, Typography, FSMBtnNext } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    nextBtn: {
      width: '343px',
      height: '48px',
      margin: 'auto',
      marginTop: theme.spacing(8),
    },
    annotation: {
      marginBottom: theme.spacing(2),
    },
  })
)

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContainerV2FSM, false)

const OwnershipPageComponent: FC<CommonPageProps> = ({ service }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.OFFER_OWNERSHIP)
  const { quote, loading, setBtnDisabled, handleSetOwnership } = useLogic(service.send)

  const body = (
    <>
      <FormBuilder
        id={FORM_ID.OWNERSHIP}
        submitHandler={handleSetOwnership}
        handleBtnDisabled={setBtnDisabled}
        customSubmit
      />
      <Typography color='textSecondary'>{t('bodyInfoText')}</Typography>
    </>
  )

  const submitBtn = (
    <FSMBtnNext
      handleClick={() => service.send(Events.NEXT)}
      buttonText={t(`next`)}
      customClassName={classes.nextBtn}
    />
  )

  if (loading) {
    return <Loading />
  }

  const header = (
    <>
      <Typography type='h1'>{t('headerTitleMobile')}</Typography>
      <Typography className={classes.headerText}>{t('headerTextMobile')}</Typography>
    </>
  )

  return quote ? (
    <>
      <MobileScreen
        send={service.send}
        breadcrumbs
        breadcrumbsBtnText={t(`back`)}
        titleHeader={t('title')}
        paragraphSubTitle={t('annotation')}
        breadcrumbsStepPercentage={70}
      >
        {header}
        <Card itemData={quote} colLeft={body} />
        {submitBtn}
      </MobileScreen>
      <DesktopScreen send={service.send} breadcrumbs breadcrumbsStepPercentage={70}>
        {header}
        <Typography type='h1'>{t('title')}</Typography>
        <Typography testId='ownership-annotation' className={classes.annotation}>
          {t('annotation')}
        </Typography>
        <Card itemData={quote} colLeft={body} showDivider />
        {submitBtn}
      </DesktopScreen>
    </>
  ) : null
}

export default OwnershipPageComponent
