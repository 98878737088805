import React, { FC } from 'react'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import useContentStyles from './useContentStyles'
import { QuoteV2Type } from '../../../../types/ApiV2'

interface ContentProps {
  offer: Partial<QuoteV2Type>
}

const Content: FC<ContentProps> = ({
  offer: {
    CarMake,
    MotorRegistrationNumber,
    CarModel,
    ManufacuringYear,
    CalculatedPrices: { priceMonthly },
  },
}) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()

  return (
    <>
      <Typography type='h3'>{t(`car`)}</Typography>
      <Typography className={classes.textDetails}>
        {`${ManufacuringYear} ${CarMake} ${CarModel}`}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`registrationNum`, { regNum: MotorRegistrationNumber })}
      </Typography>
      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`total`)}</Typography>
        <section className={classes.priceRow}>
          <Typography type={'h3'}>{priceMonthly}</Typography>
          <Typography className={classes.currency}>{t('priceMonth')}</Typography>
        </section>
      </section>
    </>
  )
}

export default Content
