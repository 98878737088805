import React, { FC } from 'react'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { InsurelyResultType } from '../../../types/insurelyTypes'
import { formatWithThousandSpace } from '../../../service/util.service'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { getDataComponentName } from '../../../util'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)

interface ComparePolicyCardProps {
  item: InsurelyResultType
}

const ComparePolicyCardComponent: FC<ComparePolicyCardProps> = ({ item }) => {
  const { t } = useTranslate(T.OFFER_COMPARE)
  const classes = useStyles()

  return (
    <div className={classes.card} data-componentname={getDataComponentName(__filename)}>
      <Typography modifiers={['bold']}>{t(`car`)}</Typography>
      <Typography modifiers={['bold']}>
        {t('priceText', {
          price: formatWithThousandSpace(item.insurance.premiumAmountYearRounded),
        })}
      </Typography>
    </div>
  )
}

export default ComparePolicyCardComponent
