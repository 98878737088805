import authMobileBg from './images/auth-mobile-bg.jpg'
import authCardBg from './images/auth-content-card-left-side.jpg'
import loadingDesktopBg from './images/loading-desktop-bg.jpg'
import loadingMobileBg from './images/loading-mobile-bg.jpg'
import CustomFontFaces from './fonts/Gotham/font-face.declaration'
import errorCardMobile from './images/error-card-mobile.jpg'
import doneMobile from './images/done-mobile.png'
import errorCardDesktop2 from './images/purchase-card-desktop.jpg'
import changeOwnerDesktop from './images/change-owner-desktop.jpg'
import logoMin from './images/logoMin.png'

import {
  ThemeBrandAssetsType,
  ThemeBrandTypographyType,
  ThemeBrandColorsType,
} from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import { ProductName } from '../../types/interface/quote.interface'
import { getProductName } from '@dg-util'

const initCallback = () => {
  const productName: ProductName = getProductName()

  const colors: ThemeBrandColorsType = {
    main: '#DA291C',
    secondary: '#ED8B00',
    background: '#F7F5F2',
    headingMain: '#484848',
    headingSecondary: '#484848',
    textMain: '#484848',
    textSecondary: '#768692',
    textError: '#DA291C',
    textPlaceholder: '#C8CFD3',
    textDisclaimer: '#B6BABC',
    buttonActive: '#DA291C',
    buttonInactive: 'rgba(218,41,28,0.5)',
    textInactive: '#D8D8D8',
    inputActive: '#ED8B00',
    inputInactive: '#ffc27e',
    linkMain: '#DA291C',
    linkSecondary: '#DA291C',
    modal: '#C4C4C4',
    shadow: '#101820',
    clean: '#FFFFFF',
    cardHeader: '#484848',
    textCardHeader: '#FFF',
    textCardFooter: '#DA291C',
  }

  const typography: ThemeBrandTypographyType = {
    heading: {
      h1: {
        fontSize: '1.75rem', // 28px
        lineHeight: 1.4,
        fontWeight: 700,
        color: colors.headingMain,
        fontFamily: 'Gotham Black',
      },
      h2: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
        fontWeight: 700,
        color: colors.headingMain,
        fontFamily: 'Gotham Black',
      },
      h3: {
        fontSize: '1.25rem', // 20px
        lineHeight: 1.4,
        fontWeight: 400,
        color: colors.headingSecondary,
        fontFamily: 'Gotham Black',
      },
      h4: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
        fontWeight: 700,
        color: colors.headingSecondary,
        fontFamily: 'Gotham Black',
      },
      h5: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
        fontWeight: 900,
        color: colors.headingSecondary,
        fontFamily: 'Gotham Black',
      },
      h6: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
        fontWeight: 400,
        fontStyle: 'italic',
        color: colors.headingSecondary,
        fontFamily: 'Gotham Black',
      },
    },
    text: {
      xSmall: {
        fontSize: '0.688rem', // 11px
        lineHeight: 1.4,
        fontFamily: 'Gotham',
      },
      small: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
        fontFamily: 'Gotham',
      },
      normal: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
        fontFamily: 'Gotham',
      },
      large: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
        fontFamily: 'Gotham Black',
      },
      xLarge: {
        fontSize: '2.5rem', // 40px
        lineHeight: 1.4,
        fontFamily: 'Gotham Black',
      },
    },
    modifiers: {
      bold: {
        fontWeight: 700,
        fontFamily: 'Gotham Black',
      },
      decorated: {
        textDecoration: 'underline',
      },
      unDecorated: {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'none',
        },
      },
      withHover: {
        '&:hover': {
          opacity: 0.75,
          cursor: 'pointer',
        },
      },
      preFormatted: {
        whiteSpace: 'pre',
      },
    },
    extraFonts: ['Gotham', 'Gotham Black', 'Gotham Light'],
    customFontFaces: CustomFontFaces,
  }

  const theme = brandTheme.setup({
    productName,
    colors: colors,
    typography: typography,
  }).theme

  const overrides = {
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: '10px',
        '&:hover': {
          color: colors.clean,
          backgroundColor: colors.buttonActive,
          opacity: 0.75,
        },
        fontFamily: 'Gotham Black',
      },
      outlined: {
        height: 48,
        '&:hover': {
          backgroundColor: colors.main,
          color: colors.clean,
        },
        '&:active': {
          backgroundColor: colors.main,
          color: colors.clean,
        },
      },
      contained: {
        '&:hover': {
          color: colors.clean,
          backgroundColor: colors.main,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: colors.clean,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            color: colors.clean,
            backgroundColor: `${colors.main} !important`,
          },
        },
        '&:disabled': {
          backgroundColor: colors.buttonInactive,
          color: colors.textInactive,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.textMain,
      },
    },
    MuiSelect: {
      icon: {
        color: colors.secondary,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${colors.textSecondary}`,
        },
        '&:after': {
          borderBottom: `2px solid ${colors.inputActive}`,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 70,
        height: 41,
        overflow: 'visible',
        '& .MuiSwitch-track': {
          width: 70,
          borderRadius: 10,
        },
        '& .MuiSwitch-thumb': {
          width: 26,
          height: 26,
        },
        '& .MuiSwitch-switchBase': {
          top: -2,
          '&.Mui-checked': {
            transform: 'translateX(30px)',
            '& .MuiSwitch-thumb': {
              backgroundColor: colors.main,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: colors.main,
            },
          },
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: {
        background: colors.main,
      },
      mainMobile: {
        background: `url(${authMobileBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      mainCard: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
      },
      secondary: {
        background: colors.main,
      },
      secondaryMobile: {
        background: `url(${doneMobile}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      error: {
        background: colors.main,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${errorCardDesktop2}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${errorCardMobile}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardSecondary: {
        background: `url(${errorCardDesktop2}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: {
        alignContent: 'center',
        background: `url(${loadingDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      loadingMobile: {
        background: `url(${loadingMobileBg}) no-repeat center bottom`,
        backgroundSize: 'cover',
      },
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${changeOwnerDesktop}) no-repeat right top scroll`,
        backgroundSize: '50%, auto, cover',
      },
      logoDesktopHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: '200px, auto, cover',
        display: 'flex',
        flex: 1,
      },
      logoMobileHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: '200px, auto, cover',
        height: 60,
      },
    },
    layout: {
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: colors.clean,
        },
        '&:hover, &:visited': {
          backgroundColor: colors.main,
        },
        '&:active': {
          backgroundColor: colors.main,
          opacity: 0.7,
        },
      },

      LMBgHeaderHeader: {
        [theme.breakpoints.down('sm')]: {
          backgroundPositionY: 'top',
        },
      },
    },
  }
  return brandTheme.setup({
    productName,
    colors: colors,
    typography: typography,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
